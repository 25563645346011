<template>
  <transition name="slide-fade" >
    <div class="right-panel d-flex flex-column" v-hotkey="keymap" v-show="show">
      <map-alerts class="mb-2">
      </map-alerts>
      <item-information class="mb-2">
      </item-information>
      <social-media :tweetStore="tweetStore" @exec-function="execFunction">
      </social-media>
    </div>
  </transition>
</template>
<script>

import MapAlerts from './MapAlerts.vue'
import ItemInformation from './ItemInformation.vue'
import SocialMedia from './SocialMedia.vue'

export default {
  props: {
    tweetStore: Object,
    execFunction: Function
  },
  components: {
    'map-alerts': MapAlerts,
    'item-information': ItemInformation,
    'social-media': SocialMedia
  },
  data() {
    return {
      show: true,
    }
  },
  methods: {
    toggle() {
      this.show = !this.show
    },
  },
  computed: {
    keymap() {
      return {
        'ctrl+space': this.toggle
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .right-panel {
    position: absolute;
    right: var(--map-widget-padding);
    //widgetPaddings + actionBarHeight
    bottom: calc(var(--map-widget-padding) * 2 + 52px);
    //100height - toolbarHeight - widgetPaddings - actionBarHeight
    height: calc(100vh - 64px - 2em - 52px);
    min-width: 450px;
    max-width: 600px;
    pointer-events: none !important;
  }
</style>
