<template>
  <div class="hidebutton" v-if="!show">
    <v-tooltip left>
      <template v-slot:activator='{ on }'>
        <v-btn
          style="color: rgb(255 255 255 / 70%) !important; background-color: rgb(20,20,20,.70); pointer-events: auto;"
          icon
          x-large
          v-on='on'
          @click="toggle"
        >
          <v-icon>mdi-twitter</v-icon>
        </v-btn>
      </template>
      <span>SOCIAL MEDIA</span>
    </v-tooltip>
  </div>
  <map-card title="Social Media" :actions="actions" v-else>
    <template v-slot:body>
      <v-list
        class="overflow-y-auto fill-height"
        three-line
        dark
        ref="tweets"
        style="background: transparent;"
      >
        <template v-for="(item, index) in tweets">
          <v-list-item
            :key="index"
          >
            <v-list-item-avatar>
              <v-img :src="item.user.profile_image_url_https"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="item.user.name"></v-list-item-title>
              <v-list-item-subtitle v-html="getText(item)" class="tweet-text">
                <!-- <component :is="getText(item)" ></component> -->
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-right">{{ item.created_at | fromNow }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </template>
  </map-card>
</template>
<script>
import { mapGetters } from 'vuex'
import IframeWindow from '@/components/map/iframeWindowController'

export default {
  props: {
    tweetStore: Object
  },
  components: {
    'map-card': () => import('@/components/MapCard.vue')
  },
  mounted() {
    const finduser = setInterval(() => {
      if (this.getUser && this.getUser.attributes && this.getUser.attributes.twitterGroup) {
        console.log(this.tweetStore)
        this.tweetStore.subscribe(this.getUser.attributes.twitterGroup)
        this.tweetStore.tweetsSocket.on('tweet', this.addTweet)
        clearInterval(finduser)
      }
    }, 500);
  },
  data() {
    return {
      show: true,
      tweets: [],
      actions: [{
        icon: 'mdi-triangle-outline mdi-rotate-90',
        help: 'Ocultar',
        action: this.toggle
      }]
    }
  },
  methods: {
    toggle() {
      this.show = !this.show
    },
    addTweet(tweet) {
      // const initialHeight = this.$refs.tweets.$el.scrollHeight;
      if (this.tweets.length === 100) {
        this.tweets.splice(48, 49)
      }
      this.tweets.unshift(tweet)
      // const vm = this
      // vm.$nextTick(() => {
      //   if (vm.$refs.tweets.$el.scrollTop > 0) {
      //     vm.$refs.tweets.$el.scrollTop += vm.$refs.tweets.$el.scrollHeight - initialHeight
      //   }
      // })
    },
    getText(item) {
      // eslint-disable-next-line
      const URLMatcher = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm
      // eslint-disable-next-line
      const text = item.retweeted_status && item.retweeted_status.extended_tweet ? item.retweeted_status.extended_tweet.full_text : item.extended_tweet ? item.extended_tweet.full_text : item.text
      return text.replace(URLMatcher, match => `<a href="${match}" target="_blank">${match}</a>`)
      // const self = this
      // return {
      //   template: '<p>' + text.replace(URLMatcher, match => `<a @click="openUrl('${match}')">${match}</a>`) + '</p>',
      //   methods: {
      //     openUrl(url) {
      //       console.log(self)
      //       self.$emit('exec-function', function() {
      //         console.log('this', this)
      //         const window = new IframeWindow({
      //           url
      //         })
      //         this.$refs.map.append(window.get())
      //       })
      //     }
      //   }
      // }
    },
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    left() {
      return (window.innerWidth / 2) - (700 / 2)
    },
    top() {
      return (window.innerHeight / 2) - (640 / 2)
    },
  }
}
</script>

<style lang="scss" scoped>
  .tweet-text {
    -webkit-line-clamp: unset !important;
  }
  /* Las animaciones de entrada y salida pueden usar */
  /* funciones de espera y duración diferentes.      */
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    // transform: translateX(-20px);
    opacity: 0;
  }

  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow:
      0 3px 1px -2px rgba (0, 0, 0, .2),
      0 2px 2px 0 rgba (0, 0, 0, .14),
      0 1px 5px 0 rgba (0, 0, 0, .12) !important;
  }

  .hidebutton {
    width: 100%;
    text-align: right;
    padding: 4px, 16px;
    opacity: 1;
  }
</style>
