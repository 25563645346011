import mapImages from '@/util/mapImages'
import Util from '@/util'
import BaseStore from './baseStore'

const ol = window.ol

class DeviceStore extends BaseStore {
  constructor(map, source, liveRouteSource) {
    super(map, source)
    this.hoveredId = null
    this.jumps = 20
    this.liveRouteLength = 5
    this.olLiveRouteSource = liveRouteSource
    this.mapRouteWith = 5
    this.devicesCounter = {
      deviceOnlineCount: 0,
      deviceOfflineCount: 0
    }
    this.mapRouteColor = [
      '#F06292',
      '#BA68C8',
      '#4DD0E1',
      '#4DB6AC',
      '#FF8A65',
      '#A1887F'
    ]
    this.liveRoutes = new Map()
    this.visibleConditionRules = {
      groupId: 'LIST',
      status: 'LIST',
      uniqueId: 'STRING',
      disabled: 'BOOLEAN',
      name: 'STRING',
    }
    this.counterMoves = {}
  }

  _getRouteStyle() {
    const color = this.mapRouteColor[Math.floor(Math.random() * this.mapRouteColor.length)]
    return new ol.style.Style({
      stroke: new ol.style.Stroke({
        color,
        width: this.mapRouteWith
      })
    })
  }

  _createMarker(item) {
    const marker = new ol.Feature({
      geometry: new ol.geom.Point(window.ol.proj.fromLonLat([
        item.position ? item.position.longitude : 0, item.position ? item.position.latitude : 0
      ])),
      name: item.id,
      population: 4000,
      rainfall: 500,
    });
    item.typeOfItem = 'devices'
    marker.setId(item.id)
    marker.set('record', item)
    this.markers.set(item.id, marker)

    const liveLine = new ol.Feature({
      geometry: new ol.geom.LineString([])
    })
    liveLine.setId(item.id)
    liveLine.setStyle(this._getRouteStyle())

    this.liveRoutes.set(item.id, liveLine)

    if (item.status === 'up' || item.status === 'online') {
      this.devicesCounter.deviceOnlineCount++
    } else {
      this.devicesCounter.deviceOfflineCount++
    }

    if (item.position) {
      const liveCoordinates = this.liveRoutes.get(item.id).getGeometry().getCoordinates()
      liveCoordinates.push(ol.proj.fromLonLat([item.position.longitude, item.position.latitude]))
      this.liveRoutes.get(item.id).getGeometry().setCoordinates(liveCoordinates)
    }
    this._updateMarker(item)
    return marker
  }

  async smoothMove(marker, record, item) {
    if (!this.counterMoves[record.id]) {
      this.counterMoves[record.id] = 0
    }

    this.counterMoves[record.id] += 1

    // marker.setStyle(this._getIcon(mapImages.device[record.category].up, record.name, {
    //   rotation: item.position ? item.position.course : 0,
    //   scale: mapImages.device[record.category].scale,
    //   offsetY: mapImages.device[record.category].offsetY,
    //   offsetX: mapImages.device[record.category].offsetX
    // }))

    const deltaLatitude = (item.position.latitude - record.position.latitude) / this.jumps
    const deltaLongitude = (item.position.longitude - record.position.longitude) / this.jumps

    let tmpLatitude = record.position.latitude
    let tmpLongitude = record.position.longitude

    const control = this.counterMoves[record.id]

    const liveCoordinates = this.liveRoutes.get(record.id).getGeometry().getCoordinates()

    for (let index = 0; !marker.get('clustered') && this.isMakerOnDisplay(marker)
    && index < this.jumps && control === this.counterMoves[record.id]; index++) {
      tmpLatitude += deltaLatitude
      tmpLongitude += deltaLongitude
      liveCoordinates.push(ol.proj.fromLonLat([tmpLongitude, tmpLatitude]))
      this.liveRoutes.get(record.id).getGeometry().setCoordinates(liveCoordinates)
      marker.getGeometry().setCoordinates(
        ol.proj.fromLonLat([
          tmpLongitude, tmpLatitude
        ])
      )
      // eslint-disable-next-line
      await this._sleep(300)
      liveCoordinates.pop()
    }

    this.liveRoutes.get(record.id).getGeometry().setCoordinates(liveCoordinates)

    record.position = item.position

    if (control === this.counterMoves[record.id]) {
      marker.getGeometry().setCoordinates(
        ol.proj.fromLonLat([
          record.position.longitude, record.position.latitude
        ])
      )
    }
  }

  _updateMarker(item) {
    const tmpMarker = this.markers.get(item.id)
    item.statusTitle = Util.statusMap[item.status]
    tmpMarker.setStyle(this._getStyle(item))
    const record = tmpMarker.get('record')
    if (record.status === 'up' && item.status === 'down') {
      this.devicesCounter.deviceOnlineCount--
      this.devicesCounter.deiceOfflineCount++
    }
    if (record.status === 'down' && item.status !== 'down') {
      this.devicesCounter.deviceOnlineCount++
      this.devicesCounter.deiceOfflineCount--
    }
    if (item.position && (!record.position || record.latitude !== item.latitude || record.longitude !== item.longitude)) {
      if (!record.position) {
        record.position = item.position
      }

      const liveCoordinates = this.liveRoutes.get(record.id).getGeometry().getCoordinates()
      liveCoordinates.push(ol.proj.fromLonLat([item.position.longitude, item.position.latitude]))
      this.liveRoutes.get(record.id).getGeometry().setCoordinates(liveCoordinates)

      this.smoothMove(tmpMarker, record, item)
    }

    const visible = this._isVisible(item)

    Object.assign(record, item)
    this.notify(item)
    if (!visible && this.olLiveRouteSource) {
      const route = this.liveRoutes.get(record.id)
      if (this.olLiveRouteSource.hasFeature(route)) {
        this.olLiveRouteSource.removeFeature(route)
      }
    }

    this._updateVisbility(tmpMarker, visible)
  }

  updateMarker(item) {
    if (!this.markers.get(item.id)) {
      return this._createMarker(item)
    }
    const tmpMarker = this.markers.get(item.id)
    const record = tmpMarker.get('record')
    if (item.status === 'online' && item.status !== record.status) {
      this.devicesCounter.deviceOnlineCount++
      this.devicesCounter.deviceOfflineCount--
    }

    if (record.status === 'online' && item.status !== record.status) {
      this.devicesCounter.deviceOnlineCount--
      this.devicesCounter.deviceOfflineCount++
    }

    this._updateMarker(item)
    return null
  }

  updatePosition(position) {
    const tmpMarker = this.markers.get(position.deviceId)
    if (!tmpMarker) {
      console.error(`Device no encontrado en memoria "${position.deviceId}"`)
      return
    }
    const record = tmpMarker.get('record')
    const item = { position }

    if (item.position && (!record.position || record.position.latitude !== item.position.latitude || record.position.longitude !== item.position.longitude)) {
      if (!record.position) {
        record.position = item.position
      }
      this.smoothMove(tmpMarker, record, item).then(() => {
        const liveCoordinates = this.liveRoutes.get(record.id).getGeometry().getCoordinates()
        liveCoordinates.push(ol.proj.fromLonLat([item.position.longitude, item.position.latitude]))
        while (liveCoordinates.length > this.liveRouteLength) {
          liveCoordinates.shift()
        }
        this.liveRoutes.get(record.id).getGeometry().setCoordinates(liveCoordinates)
      })
    }
  }

  _getStyle(item) {
    let category = mapImages.device[item.category]

    if (!category) {
      console.error(`Categoría de unidad no definida "${item.category}"`)
      category = mapImages.device.default
    }
    let icon = category.down
    const scale = category.scale
    if (item.status === 'online') {
      icon = category.up
      if (item.selected) {
        icon = category.up_selected
      }
    } else if (item.selected) {
      icon = category.down_selected
    }
    return this._getIcon(icon, item.name, {
      // rotation: item.position ? item.position.course : 0,
      scale,
      offsetY: category.offsetY,
      offsetX: category.offsetX
    })
  }

  _getStyleSelected(item) {
    let category = mapImages.device[item.category]

    if (!category) {
      console.error(`Categoría de unidad no definida "${item.category}"`)
      category = mapImages.device.default
    }
    let icon = category.down_selected
    const scale = category.scale
    if (item.status === 'online') {
      icon = category.up_selected
    }

    return this._getIcon(icon, item.name, {
      // rotation: item.position ? item.position.course : 0,
      scale,
      offsetY: category.offsetY,
      offsetX: category.offsetX
    })
  }

  hoverMarker(id) {
    if (!id && this.hoveredId) {
      const marker = this.markers.get(this.hoveredId)
      marker.setStyle(this._getStyle(marker.get('record')))
      this.hoveredId = null
    } else if (id !== this.hoveredId) {
      const marker = this.markers.get(id)
      marker.setStyle(this._getStyleSelected(marker.get('record')))
      this.hoveredId = id
    }
  }

  updateMarkersStyle() {
    this.markers.forEach((marker) => {
      marker.setStyle(this._getStyle(marker.get('record')))
    })
  }
}

export default DeviceStore
