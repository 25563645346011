<template >
  <div class="hidebutton" v-if="getUser.readonly">
  </div>
  <div class="hidebutton" v-else-if="!murphy_widget && !getUser.readonly">
    <v-tooltip right>
      <template v-slot:activator='{ on }'>
        <v-btn
          style="color: rgb(255 255 255 / 70%) !important; background-color: rgb(20,20,20,.70); pointer-events: auto;"
          icon
          x-large
          v-on='on'
          @click="toggleMurphyWidget"
        >
          <v-icon>mdi-card-account-details-outline</v-icon>
        </v-btn>
      </template>
      <span>Murphy</span>
    </v-tooltip>
  </div>
  <map-card title="MURPHY" class="card-div" :actions="actions" v-else-if="murphy_widget && !getUser.readonly">
    <template v-slot:body>
      <v-tabs fixed-tabs height="30" background-color="secondary" v-model="tab" dark>
        <v-tab>
          VEHICULOS
        </v-tab>
        <v-tab>
          PERSONAL
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" style="height: calc(100% - 30px)" class="overflow-y-auto" dark>
        <v-tab-item dark>
          <v-list dark class="pa-0">
            <placa ref="tab0"/>
          </v-list>
        </v-tab-item>
        <v-tab-item dark>
          <v-list dark class="pa-0">
            <licences ref="tab1"/>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </map-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Licences from './Licences.vue'
import Placa from './Placa.vue'

export default {
  components: {
    'map-card': () => import('@/components/MapCard.vue'),
    Licences,
    Placa
  },
  props: {
  },
  mounted() {
  },
  data() {
    return {
      tab: 0,
      actions: [{
        icon: 'mdi-content-copy',
        help: 'Copiar',
        action: () => {
          this.$refs[('tab' + this.tab)].copy()
        }
      }, {
        icon: 'mdi-triangle-outline mdi-rotate-270',
        help: 'Ocultar',
        action: this.toggleMurphyWidget
      }]
    }
  },
  methods: {
    ...mapActions('map', ['toggleMurphyWidget'])
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapState('map', ['murphy_widget'])
  },
}
</script>

<style lang="scss" scoped>
  .card-div {
    width: 500px;
    height: 430px;
  }
</style>
