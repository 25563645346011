<template>
  <transition name="slide-fade">
    <v-btn-toggle
      v-model="toggleMultiple"
      active-class="black"
      dense
      multiple
      class="map-actions"
      v-hotkey="keymap"
      v-show="show"
      dark
    >
      <v-tooltip
        v-for="(action, index) in actions"
        :key="index"
        top
      >
        <template v-slot:activator='{ on }'>
          <v-btn
            x-large
            @click="action.action"
            style="background-color: #5f6360;"
            v-on='on'
          >
            <v-icon v-html="action.icon" color="white"></v-icon>
          </v-btn>
        </template>
        <span>{{action.help}}</span>
      </v-tooltip>
    </v-btn-toggle>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {},
  mounted() {
    this.actions = [{
      icon: '$camera-white',
      active: this.getShowCameras,
      help: 'CAMARAS',
      action: () => {
        this.setShowCameras(!this.getShowCameras)
      }
    }, {
      icon: '$bus-white',
      active: this.getShowBuses,
      help: 'TRANSPORTE',
      action: () => {
        this.setShowBuses(!this.getShowBuses)
      }
    // }, {
    //   icon: '$lpr-white',
    //   active: this.getShowLprs,
    //   help: 'LPRS',
    //   action: () => {
    //     this.setShowLprs(!this.getShowLprs)
    //   }
    }, {
      icon: '$device-white',
      active: this.getShowDevices,
      help: 'UNIDADES',
      action: () => {
        this.setShowDevices(!this.getShowDevices)
      }
    }, {
      icon: '$sensor-white',
      active: this.getShowSensors,
      help: 'BOTONES DE PANICO',
      action: () => {
        this.setShowSensors(!this.getShowSensors)
      }
    }, {
      icon: 'mdi-alert-outline',
      active: this.getShowIncidents,
      help: 'INCIDENTES',
      action: () => {
        this.setShowIncidents(!this.getShowIncidents)
      }
    }, {
      icon: '$geofence-white',
      active: this.getShowGeofences,
      help: 'GEOCERCAS',
      action: () => {
        this.setShowGeofences(!this.getShowGeofences)
      }
    }]
    this.actions.forEach((action, index) => {
      if (action.active) {
        this.toggleMultiple.push(index)
      }
    })
  },
  data() {
    return {
      show: true,
      toggleMultiple: [],
      actions: []
    }
  },
  methods: {
    ...mapActions('map', [
      'setShowCameras',
      'setShowBuses',
      'setShowLprs',
      'setShowDevices',
      'setShowSensors',
      'setShowIncidents',
      'setShowGeofences'
    ]),
    toggle() {
      this.show = !this.show
    },
  },
  computed: {
    ...mapGetters('map', [
      'getShowCameras',
      'getShowBuses',
      'getShowLprs',
      'getShowDevices',
      'getShowSensors',
      'getShowIncidents',
      'getShowGeofences'
    ]),
    keymap() {
      return {
        'ctrl+space': this.toggle,
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  .map-actions {
    position: absolute;
    bottom: var(--map-widget-padding);
    opacity: 0.8;
    left: 50%;
    transform: translateX(-50%);
    border: solid;
  }
   /* Las animaciones de entrada y salida pueden usar */
  /* funciones de espera y duración diferentes.      */
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    // transform: translateY(20px);
    opacity: 0;
  }
</style>
