class TweetUtil {
  constructor() {
    console.log('tweeet')
    this.tweetsSocket = window.io('/', { path: '/util/tweet/socket.io' })
    // this.keywords = []
    this.tweetsSocket.on('keywords', (data) => {
      this.keywords = data
    })
  }

  subscribe(group) {
    this.tweetsSocket.emit('subscribe', group)
  }

  track(keywords) {
    this.tweetsSocket.emit('track', keywords)
    this.keywords = keywords
  }
}

export default TweetUtil
